
import DatePicker from "@/components/utils/IDatePicker.vue";
import ValidationMixin from "@/mixins/validation";
import {
  SystemProduct,
  ProductTypes,
} from "@/store/modules/product/product.types";
import { DefaultState } from "@/types/types";
import { Validator } from "@/types/validator";
import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const productX = namespace("Product");

@Component({
  components: { DatePicker },
})
export default class EditProduct extends Mixins(ValidationMixin) {
  @productX.State(ProductTypes.UPDATE_PRODUCT_STATE)
  public updateProduct!: DefaultState;

  @productX.State(ProductTypes.PRODUCT_DATA)
  public userProduct!: SystemProduct;

  @productX.Mutation(ProductTypes.SET_UPDATE_PRODUCT_DIALOG)
  public setUpdateProduct!: (updateProduct: boolean) => void;

  @productX.Action(ProductTypes.UPDATE_PRODUCT)
  public saveProduct!: (farmer: SystemProduct) => Promise<void>;

  public updateProductStep = 1;

  public isValid = false;

  public product: SystemProduct = {
    id: -1,
    name: "",
    description: "",
    active: false,
    createdAt: new Date(),
  };

  @Ref("form") public formRef!: Validator;

  closeUpdateProductDialog(): void {
    this.setUpdateProduct(false);
  }

  async saveProductToDb(): Promise<void> {
    await this.formRef.validate();

    if (this.isValid) {
      await this.saveProduct(this.product);
    }
  }

  @Watch("userProduct")
  onDataChanged(value?: SystemProduct): void {
    this.product = Object.assign({}, value);
  }

  mounted(): void {
    this.product = Object.assign({}, this.userProduct);
  }
}
